<template>
  <v-app>
    <v-content>
      <v-container fluid fill-height class="grey">
        <slot />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {}
</script>
